import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
declare var $: any;
declare var BarraProgreso:any;

const routes: Routes = [
  {
     path: '',
     loadChildren: ()=> import("./inicio/inicio.module").then(e=>{
       return new Promise(resolve =>{
          resolve(e.InicioModule);
       });
     })
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
